.react-datepicker-wrapper,
.react-datepicker-wrapper .react-datepicker__input-container {
  width: 100%;
}

/* React-Quill*/
.ql-container .ql-editor {
  min-height: 200px;
  height: 100%;
  font-family: "Arial";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "Pequeno" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "Grande" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "Enorme" !important;
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "Título 1" !important;
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "Título 2" !important;
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "Título 3" !important;
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "Título 4" !important;
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "Título 5" !important;
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "Título 6" !important;
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: attr(data-value) !important;
}

/* Set dropdown font-families */

.ql-font .ql-picker-item[data-value="Arial"]::before {
  font-family: "Arial";
}

.ql-font .ql-picker-item[data-value="Arial-Black"]::before {
  font-family: "Arial Black";
}

/* Set content font-families */
.ql-font-Arial-Black {
  font-family: "Arial Black";
}

/* form-group error*/
.form-group.has-error .error-detail {
  margin-top: 4px;
}
.form-group.has-error .error-detail span {
  color: red;
  font-size: 14px;
}
