.f-no-select {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.m-tree {
  position: relative;
  padding-left: 4px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.m-draggable {
  position: absolute;
  opacity: 0.8;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.m-node {
  width: fit-content;
}
.m-node.placeholder > * {
  visibility: hidden;
}
.m-node.placeholder {
  border: 1px dashed #ccc;
}
.m-node .inner {
  position: relative;
  min-width: 11em;
  cursor: pointer;
  margin: 0.25em 0;
  padding-left: 0.75em;
  line-height: 1.2em;
}
.m-node .inner.hide {
  display: none;
}
.m-node .collapse {
  position: absolute;
  left: -4px;
  cursor: pointer;
  font-size: 1.2em;
}
.m-node .caret-right:before {
  content: "\25B8";
}
.m-node .caret-down:before {
  content: "\25BE";
}
